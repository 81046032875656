<div fxLayout="row">
    <mat-label [ngStyle]="captionStyles()">
        {{question.Caption}}
        <mat-label *ngIf="question.IsRequired" class="color-city-red"> *</mat-label>
    </mat-label>
</div>

<mat-radio-group [(ngModel)]="selectedOption" (ngModelChange)="onOptionSelected(selectedOption)">
    <div *ngFor="let option of options; let i = index" fxLayout="column"  >
        <div fxLayout="row">
            <div fxLayout="column">
                <mat-radio-button [value]="option" name="questionOption{{question.Id}}">
                </mat-radio-button>
            </div>
            <div fxFlex="80" fxLayout="column" fxLayoutAlign="center start" [ngStyle]="questionDetailsStyles()">
                <span class="checkbox-textwrap color-dark-blue font-size-body-text" >
                    {{option}}
                </span>
            </div>
        </div>
        <div fxLayout="row" *ngIf="selectedOption === option && nextQuestions">
            <div fxFlex="100" fxLayout="column">
                <div class="rules-render" *ngFor="let nextQuestion of nextQuestions">
                    <app-permit-question-render [question]="nextQuestion"
                        [hasContentError]="nextQuestion.hasContentError"></app-permit-question-render>
                </div>
            </div>
        </div>
    </div>
</mat-radio-group>

<div *ngIf="question.HasContentError && question.IsRequired && !question.Answer">
    <mat-error>{{ isRequiredMessageDisplay }}</mat-error>
</div>