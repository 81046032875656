<div class="main-content">
  <div class="profile-header">
    <h1> {{ ('userprofile_your_profile' | translate)}}</h1>
  </div>
  <mat-divider></mat-divider>
  <div>
    <form [formGroup]="userProfileFormGroup" class="profile-form">
      <div data-testid="user_profile_photo" (click)="profileImageOnClick()">
        <mat-icon class="color-city-blue profile-camera-icon">camera_enhance</mat-icon>
        <img *ngIf="profileImgLink" [src]="profileImgLink | safe: 'resourceUrl'" (error)="profileImgLink=''"
          class="profile-image" />
        <mat-icon *ngIf="!profileImgLink" class="profile-image-empty-icon">account_circle</mat-icon>
      </div>
      <div *ngIf="newImagePath">
        <div class="divider"></div>
        <button mat-raised-button (click)="savePhotoOnClick()" id="btn_savephoto" data-testid='user_profile_save_photo'>
          {{"userprofile_btn_update_photo" | translate }}
        </button>
      </div>
      <div class="divider"></div>
      <div class="divider"></div>
      <mat-divider></mat-divider>
      <mat-form-field appearance="fill" class="input">
        <mat-label class="color-city-neutral">{{ ('userprofile_first_name' | translate) }}</mat-label>
        <input matInput disabled value={{firstName}} data-testid='user_profile_first_name'>
      </mat-form-field>
      <mat-divider></mat-divider>
      <mat-form-field appearance="fill" class="input">
        <mat-label class="color-city-neutral">{{ ('userprofile_last_name' | translate) }}</mat-label>
        <input matInput disabled value={{lastName}} data-testid='user_profile_last_name'>
      </mat-form-field>
      <mat-divider></mat-divider>
      <mat-form-field appearance="fill" class="input">
        <mat-label class="color-city-neutral">{{ ('userprofile_phone' | translate) }}</mat-label>
        <input matInput disabled value={{phoneNumber}} data-testid='user_profile_phone'>
      </mat-form-field>
      <mat-divider></mat-divider>
      <mat-form-field appearance="fill" class="input">
        <mat-label class="color-city-neutral">{{ ('userprofile_email' | translate) }}</mat-label>
        <input matInput disabled value={{emailAddress}} data-testid='user_profile_email'>
      </mat-form-field>
      <mat-divider></mat-divider>
    </form>
  </div>

  <div class="form-list" *ngIf="inductionModuleEnable && profileRapidGlobalRequired">
    <div fxLayout="row" fxLayoutAlign="space-between end">
      <h5> {{ ('userprofile_rapid_global_header' | translate)}} <mat-icon>help_outline</mat-icon>
      </h5>
      <button id="btn_addrapidglobal" mat-fab (click)="addRapidGlobal()"
        data-testid='user_profile_add_rapid_global'>
        <mat-icon>note_add</mat-icon>
      </button>
    </div>
    <div class="divider"></div>
    <mat-divider class="table-divider-header"></mat-divider>
    <div>
      <table mat-table [dataSource]="rapidGlobalInfos" multiTemplateDataRows>
        <ng-container matColumnDef="companyName">
          <th mat-header-cell *matHeaderCellDef fxFlex="82" fxLayoutAlign="start center">
            {{ ('userprofile_rapid_global_company_table_header' | translate)}} </th>
          <td mat-cell class="color-dark-blue" *matCellDef="let company" fxFlex="82" fxLayoutAlign="start center">
            {{company.companyName}}
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef fxFlex="18" fxLayoutAlign="end center"> </th>
          <td mat-cell *matCellDef="let company" fxFlex="18" fxLayoutAlign="end center">
            <mat-icon color="primary" *ngIf="!company.companyId" (click)="editRapidGlobal(company)"
              data-testid="user_management_rapid_edit_icon">
              edit
            </mat-icon>
            <mat-icon color="primary" *ngIf="!company.companyId" (click)="deleteRapidGlobal(company)"
              data-testid="user_management_rapid_delete_icon">
              delete
            </mat-icon>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedRapidGlobalColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedRapidGlobalColumns;" class="element-row">
        </tr>

      </table>
      <div class="divider"></div>
      <div class="divider"></div>
    </div>
  </div>

  <div>
    <button mat-raised-button type="submit" class="change-button" *ngIf="revealElement" (click)="updateUserDetails()"
      id="btn_changeUserDetails" data-testid='user_profile_update_user'>
      {{'userprofile_update_details' | translate }}
    </button>
    <button mat-raised-button type="submit" class="change-button" *ngIf="revealElement" (click)="changepassword()"
      id="btn_changepassword" data-testid='user_profile_change_password'>
      {{'userprofile_change_password' | translate }}
    </button>
  </div>
  <div class="divider"></div>
  <div class="deactivate-user" *ngIf="revealElement">
    <a class="color-city-blue" id="btn_deactivateuser" data-testid="btn_deactivate_user"
      (click)="deactivateUser()">{{'user_profile_deactivate_user' | translate }}</a>
  </div>

  <div class="divider"></div>
  <div class="form-list" *ngIf="inductionModuleEnable && profileInductionRequired">
    <div fxLayout="row" fxLayoutAlign="space-between end">
      <h5> {{ ('userprofile_siteinduction_header' | translate)}} <mat-icon>help_outline</mat-icon>
      </h5>
      <button id="btn_addInduction" mat-fab color="primary" (click)="addSiteInduction()"
        data-testid='user_profile_add_site_induction'>
        <mat-icon>note_add</mat-icon>
      </button>
    </div>

    <div class="divider"></div>
    <mat-divider class="table-divider-header"></mat-divider>
    <div>
      <table mat-table [dataSource]="siteInductionInfos" multiTemplateDataRows>
        <ng-container matColumnDef="brandName">
          <th mat-header-cell *matHeaderCellDef fxFlex="82" fxLayoutAlign="start center">
            {{ ('userprofile_siteinduction_partner_table_header' | translate)}} </th>
          <td mat-cell class="color-dark-blue" *matCellDef="let siteinduction" fxFlex="82" fxLayoutAlign="start center">
            {{siteinduction.brandName}} </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef fxFlex="18" fxLayoutAlign="end center">
            {{ ('userprofile_siteinduction_status_table_header' | translate)}} </th>
          <td mat-cell *matCellDef="let siteinduction" fxFlex="18" fxLayoutAlign="end center">
            <mat-icon class="color-city-red" *ngIf="getStatus(siteinduction) === 'inactive'">do_not_disturb_alt</mat-icon>
            <mat-icon class="color-city-blue" *ngIf="getStatus(siteinduction) === 'active'">task_alt</mat-icon>
            <mat-icon class="color-city-yellow" *ngIf="getStatus(siteinduction) === 'error'">error_outline</mat-icon>
          </td>
        </ng-container>
        <ng-container matColumnDef="expandedInductionDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="displayedSiteInductionColumns.length">
            <div class="element-detail" [@detailInductionExpand]="element.isExpanded ? 'expanded' : 'collapsed'">
              <div fxLayout="column" fxFlex="100">
                <div fxLayout="row">
                  <div fxFlex="82" class="expanded-header-label color-city-neutral" fxLayoutAlign="start center">
                    {{('userprofile_siteinduction_id' | translate)}} {{element.inductionId}}
                  </div>
                  <div fxFlex="18" fxLayoutAlign="end center">
                    <mat-icon color="primary" (click)="editSiteInduction(element)"
                      data-testid="user_management_induction_edit_icon">
                      edit
                    </mat-icon>
                    <mat-icon color="primary" (click)="deleteSiteInduction(element)"
                      data-testid="user_management_induction_delete_icon">
                      delete
                    </mat-icon>
                  </div>
                </div>
                <div class="divider"></div>
              </div>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedSiteInductionColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedSiteInductionColumns;" class="element-row"
          [class.expanded-row]="element.isExpanded" (click)="element.isExpanded = !element.isExpanded">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedInductionDetail']" class="detail-row"></tr>
      </table>
    </div>
  </div>

  <div class="close-button">
    <button type="submit" mat-raised-button id="userprofile_close" data-testid="userprofile_close"
      (click)="closeUserProfile()">
      {{'userprofile_close' | translate }}
    </button>
  </div>
</div>