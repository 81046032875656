import { Injectable } from '@angular/core';
import { BarcodeScanner, CheckPermissionOptions, CheckPermissionResult, ScanOptions, ScanResult } from '@capacitor-community/barcode-scanner';


@Injectable({
  providedIn: 'root'
})
export class BarcodeScannerService {
  public async checkPermission(options: CheckPermissionOptions| undefined): Promise<CheckPermissionResult> {
    return BarcodeScanner.checkPermission(options);
  }

  public async openAppSettings(): Promise<void> {
    return BarcodeScanner.openAppSettings();
  }
  public async hideBackground(): Promise<void> {
    BarcodeScanner.hideBackground();
  }
  public async startScan(options: ScanOptions| undefined): Promise<ScanResult> {
    return BarcodeScanner.startScan(options);
  }

  public async stopScan(): Promise<void> {
    return await BarcodeScanner.stopScan();
  }
  public async showBackground(): Promise<void> {
    return await BarcodeScanner.showBackground();
  }

 
}

