import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationGuard implements CanActivate {
  previousCheckTime = Date.now();
  currentTime = Date.now();

  constructor(
    private _configurationService: ConfigurationService
  ){
  }

  public async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {

    const shouldRetrieve = await this._configurationService.shouldRetrieveFromAPI();
    if(shouldRetrieve)
    {
      await this._configurationService.getAndSetLocalConfigurations(false);
    }

    return true;
  }
}
