import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http';
import { finalize, from, lastValueFrom, map, Observable, of } from 'rxjs';
import { LoaderService } from '../services/loader/loader.service';
import { AuthService } from '../services/auth/auth.service';

@Injectable()
export class CityHttpInterceptor implements HttpInterceptor {

  bearerToken: string | undefined;

  constructor(private _loaderService: LoaderService,
              private _authService: AuthService) {        
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.headers.get("skip")){
      this._loaderService.show();
    }

    return from(this.handler(request, next));
  }

  private async handler(request: HttpRequest<any>, next: HttpHandler):Promise<HttpEvent<any>> {
      request = await this.addAuthToken(request);

      return lastValueFrom(next.handle(request).pipe(map(event => {
        if (event instanceof HttpResponse) {
          if(event.status >= 200 && event.status < 300){
            //success
          }else{
            if (event.status === 401) {
              // TODO
            }else if(event.status === 0){
              // TODO
            } 

            alert(JSON.stringify(event));
          }
        }
        return event;
    }),finalize(()=> {
      this._loaderService.hide();
    })));
  }

  async addAuthToken(request: HttpRequest<any>) {
    let headers = (request.headers) ? request.headers : new HttpHeaders();
    this.bearerToken = await this._authService.getTokenFromStorage() as string;

    if(request.url.toLowerCase().indexOf("verifyuser") === -1 &&
       request.url.toLowerCase().indexOf("validateuserpassword") === -1 && 
       request.url.toLowerCase().indexOf("getlatestappversion") === -1 &&
       request.url.toLowerCase().indexOf("getconfigurations") === -1){
        headers = headers.append('Authorization',  `Bearer ${this.bearerToken}`);
    }

    if (this.bearerToken){
      if (request.method.toLowerCase() != 'get')
      {
        if(!request.headers.has('content-type')){
          headers = headers.append('content-type',  `application/json`)
        }
      }
    }

    let curRequsst = request.clone({headers});

    return curRequsst;

  }
}