import { Injectable,  NgZone } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';
import { Notification, User } from 'src/app/models/user.model';
import { Geolocation } from '@capacitor/geolocation';
import { Site } from 'src/app/models/site.model';
import { UtilsService } from 'src/app/services/util/util-service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';

import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { Capacitor, registerPlugin } from '@capacitor/core';
import { LocalizationService } from '@app/components/internationalization/localization.service';
import { BackgroundGeolocationPlugin } from "@capacitor-community/background-geolocation";
import { environment } from 'src/environments/environment';
import { ConfigurationService } from '../configuration/configuration.service';
const BackgroundGeolocation = registerPlugin<BackgroundGeolocationPlugin>("BackgroundGeolocation");

@Injectable({
  providedIn: 'root'
})
export class NotificationPushService {
    private deviceToken: string = '';
    private deviceType: string = "unknown";
    private notificationId: string | undefined;
    currentUser: User | undefined;
    private watchPositionCallbackId : string = "";

  constructor(
    public _router: Router,
    private _authService: AuthService,
    private _userService: UserService,
    private _utilsService: UtilsService,
    private _localizationService : LocalizationService,
    private _ngZone: NgZone,
    private _configurationService : ConfigurationService,
  ){
    this._authService.getUser()
    .then(user => this.currentUser = user);

    if (Capacitor.isPluginAvailable('PushNotifications')){

      PushNotifications.removeAllListeners();

      PushNotifications.addListener('registration',
        (token: Token) => {
          this.deviceToken = token.value;
          switch(Capacitor.getPlatform()) {
          case "ios":
            this.deviceType = "apns";
            break;
          case "android":
            this.deviceType = "fcm";
              break;
          default:
            this.deviceType = "unknown";
          };
        }
      );

      PushNotifications.addListener('registrationError', 
        (error: any) => {
          console.log('Error on registration: ' + JSON.stringify(error));
        }
      );

      PushNotifications.addListener('pushNotificationReceived',
        (notification: PushNotificationSchema) => {
          this._ngZone.run(() => {
            alert(this._localizationService.translate("notification_outofrange"));
            this._router.navigate(['sitesignout']);
          });                
        }
      );

      PushNotifications.addListener('pushNotificationActionPerformed',
        (notification: ActionPerformed) => {
          _ngZone.run(() => {
            console.log('Push action performed: ' + JSON.stringify(notification));
            this._router.navigate(['sitesignout']);
          });
        }
      );
    }        
  }

  registerNotifications = async () => {
    if(this._utilsService.isMobileAndTablet()){  
      let permStatus = await PushNotifications.checkPermissions();
    
      if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions();
      }
    
      if (permStatus.receive === 'granted') {
        await PushNotifications.register();
      }

    }
  }

  async startLocationWatch(site: Site): Promise<string> {
    if (site && site.isGeofencingEnable) {
      await this.endLocationWatch();
      if (this._utilsService.isMobileAndTablet()) {
        this._userService.registerForNotification({
          id: undefined,
          userId: this.currentUser?.id,
          deviceType: this.deviceType,
          deviceToken: this.deviceToken,
          content: 'Please sign out of the site.'
        } as Notification)
          .subscribe({
            next: notification => {
              if (notification) {
                this.notificationId = notification.id
              }
            },
            error: error => {
              console.log(JSON.stringify(error));
            },
          });
        await this.startMobileAppLocationWatch(site);
      } else {
        await this.startWebLocationWatch(site);
      }
    }
    return this.watchPositionCallbackId;
  }

  private async startMobileAppLocationWatch(site: Site): Promise<string> {
    const options = {
      backgroundMessage: environment.mobileGeolocationWatchBackgroundMessage,
      backgroundTitle: environment.mobileGeolocationWatchBackgroundTitle,
      requestPermissions: environment.mobileGeolocationWatchRequestPermissions,
      stale: environment.mobileGeolocationWatchStale,
      distanceFilter: environment.mobileGeolocationWatchDistanceFilter
    };
    const id = await BackgroundGeolocation.addWatcher(options, async (location) => {
      if (location &&
        !this._utilsService.isUndefinedOrNull(location?.latitude) &&
        !this._utilsService.isUndefinedOrNull(location?.longitude) &&
        !this._utilsService.isUndefinedOrNull(site.lat) &&
        !this._utilsService.isUndefinedOrNull(site.lon)) {
        const distanceInKilometers = this._utilsService.getDistanceBetweenTwoPointsinKM(location.latitude,
          location.longitude, site.lat, site.lon);
        const distanceInMeters = this._utilsService.convertKilometersToMeters(distanceInKilometers);

        if (!this.checkSiteIsInRange(site, distanceInMeters)) {
          await this.outOfRangeAction();
        }
      }
    });
    return this.watchPositionCallbackId = id;
  }

  private async startWebLocationWatch(site: Site): Promise<string> {
    const options = { 
      enableHighAccuracy: environment.webGeolocationWatchEnableHighAccuracy, 
      maximumAge: environment.webGeolocationWatchMaximumAge, 
      timeout: environment.webGeolocationWatchTimeout
    };
    const id = await Geolocation.watchPosition(options, async (devicePosition) => {
      if (devicePosition &&
        !this._utilsService.isUndefinedOrNull(devicePosition?.coords?.latitude) &&
        !this._utilsService.isUndefinedOrNull(devicePosition?.coords?.longitude) &&
        !this._utilsService.isUndefinedOrNull(site.lat) &&
        !this._utilsService.isUndefinedOrNull(site.lon)) {
        const distanceInKilometers = this._utilsService.getDistanceBetweenTwoPointsinKM(devicePosition.coords.latitude,
          devicePosition.coords.longitude, site.lat, site.lon);
        const distanceInMeters = this._utilsService.convertKilometersToMeters(distanceInKilometers);

        if (!this.checkSiteIsInRange(site, distanceInMeters)) {
          await this.outOfRangeAction();
        }
      }
    });
    return this.watchPositionCallbackId = id;
  }
  
  endLocationWatch() {
    if (!this._utilsService.isUndefinedOrNullOrEmpty(this.watchPositionCallbackId)) {
      if (this._utilsService.isMobileAndTablet()) {
        return BackgroundGeolocation.removeWatcher({ id: this.watchPositionCallbackId })
          .then(() => {
            this.watchPositionCallbackId = '';
          });
      } else {
        return Geolocation.clearWatch({ id: this.watchPositionCallbackId })
          .then(() => {
            this.watchPositionCallbackId = '';
          });
      }
    } else return undefined;
  }

  private async outOfRangeAction() {
    let shouldRetryNotification = false;

    this.endLocationWatch();
    await this._userService.setOutOfRange("true");
    if (this._utilsService.isMobileAndTablet()) {
      const notificationRetryCount = Number(await this._userService.getOutOfRangeRetryCount());

      if (this.notificationId !== undefined
        && notificationRetryCount < await this._configurationService.getOutOfRangeNotificationMaxRetryCount()) {
          this._userService.pushNotification(this.notificationId as string)
          .subscribe()
          .add(async () => {
            shouldRetryNotification = await this.shouldRetryNotificationCheck();

            // Wait for the specified interval before re-triggering outOfRangeAction to get the out of range notification
            if(shouldRetryNotification){
              setTimeout(async () => {
                await this.outOfRangeAction();
              }, await this._configurationService.getOutOfRangeNotificationIntervalInMinutes() * 60 * 1000);
            }
          });
      }
    } else {
      alert(this._localizationService.translate("notification_outofrange"));
      this._router.navigate(['sitesignout']);
    }
  }

  private async shouldRetryNotificationCheck(): Promise<boolean>{
    let shouldRetryNotification = false;
    const retryCountString = await this._userService.getOutOfRangeRetryCount();
    const retryCount = Number(retryCountString);

    // Set retryCount to 0 if no value exists yet (no retries triggered), else increment by 1
    if(retryCountString === ''){
      shouldRetryNotification = true;
      await this._userService.setOutOfRangeRetryCount(JSON.stringify(0));
    }
    else if(retryCount < await this._configurationService.getOutOfRangeNotificationMaxRetryCount()){
      if(retryCount + 1 >= await this._configurationService.getOutOfRangeNotificationMaxRetryCount()){
        shouldRetryNotification = false;
      }
      else{
        shouldRetryNotification = true;
      }

      await this._userService.setOutOfRangeRetryCount(JSON.stringify(retryCount + 1));
    }

    return shouldRetryNotification;
  }

  private checkSiteIsInRange(site: Site, distanceInMeters: number): boolean {
    let result: boolean = false;
    const rangeToValidate = site.geofencingRangeMeter > 0 ? site.geofencingRangeMeter : environment.geofencingRangeMeterDefault;
    if (site.isGeofencingEnable) {
      if (distanceInMeters <= rangeToValidate) {
        result = true;
      }
    }
    else {
      result = true;
    }

    if(result){
      this._userService.clearOutOfRangeSettings();
    }

    return result;
  }
}