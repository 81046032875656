import { Component, Input, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss']
})
export class SignatureComponent implements AfterViewInit {

  @Input('question') question : any;

  @ViewChild(SignaturePad) signaturePad: SignaturePad | undefined;
  @ViewChild('signatureWrapper') signatureWrapper: ElementRef | undefined;


  signaturePadOptions: Object = { 
    'minWidth': 2,
    'canvasWidth': window.innerWidth * 0.92,
    'canvasHeight': 150,
    'backgroundColor': 'white'
  };

  constructor() { }

  ngAfterViewInit(): void {
    if (this.question){
      if (this.question.answer === null || this.question.answer === undefined || this.question.answer === "") 
      {
        this.signaturePad?.clear();
      } else {
        this.signaturePad?.clear();
        this.signaturePad?.fromDataURL(this.question.answer);
      }

      this.setSignaturePad();
    }
  }

  drawComplete() {
    this.question.answer = this.signaturePad?.toDataURL();
  }

  drawStart() {
  }

  clearSignature() {
    this.signaturePad?.clear();
    this.question.answer = "";
  }

  setSignaturePad() {
    if (this.signatureWrapper && this.signaturePad) {
      this.signaturePad.queryPad()._canvas.width = this.signatureWrapper.nativeElement.clientWidth - 1;
    }
  }

  resizeSignaturePad() {
    this.setSignaturePad();
    this.clearSignature();
  }
}
