import { Browser, OpenOptions } from '@capacitor/browser';
import { Injectable } from '@angular/core';
import { PluginListenerHandle } from '@capacitor/core';
  
  @Injectable({
    providedIn: 'root'
  })
  export class BrowserService {
  
    public async open(options: OpenOptions): Promise<void> {
      return await Browser.open(options);
    }
  
    public async openUrl(url: string): Promise<void> {
      return await this.open({ url });
    }
  
    public async close(): Promise<void> {
      return await Browser.close();
    }  
  
    public onPageLoaded(listenerFunc: () => void): PluginListenerHandle {
      return Browser.addListener('browserPageLoaded', listenerFunc);
    }
  
    public async removeAllListeners(): Promise<void> {
      return await Browser.removeAllListeners();
    }  
    
    public openExternalBroswer(url: string): void {
      window.location.replace(url);
    }

    public openExternalhref(url: string): void {
      window.location.href = url;
    }

  }  