import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator} from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Brand } from '@app/models/brand.model';
import { Site } from '@app/models/site.model';
import { SiteApiService } from '@app/services/site/site.api.service';
import { environment } from '@env/environment';
import { debounceTime, distinctUntilChanged, forkJoin } from 'rxjs';
import { LocalizationService } from '../internationalization/localization.service';
import { Router } from '@angular/router';
import * as ExcelJS from 'exceljs';
import { SiteManagementEditComponent } from '../site-management-edit/site-management-edit.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as QRCode from 'qrcode';
import { Worksheet } from 'exceljs';
import { NavigationService } from '@app/services/navigation/navigation.service';

export interface ExtendedSite {
  site: Site,
  edit: any
}

@Component({
  selector: 'app-site-management',
  templateUrl: './site-management.component.html',
  styleUrls: ['./site-management.component.scss']
})
export class SiteManagementComponent {
  sites : Site[] = [];
  brands : Brand[] = [];
  sitesFilterDisplay : Site[] = [];
  brandsFilterDisplay : Brand[] = [];
  searchedSites : Site[] = [];

  selectedBrand : Brand | null = null;
  selectedSite : Site | null = null;
  includeInactiveBrands : boolean = false;
  includeInactiveSites : boolean = false;
  
  @ViewChild('paginator') paginator: MatPaginator;
  filteredSites: MatTableDataSource<ExtendedSite> = new MatTableDataSource<ExtendedSite>([]);
  displayedSiteColumns: string[] = ['Name', 'Code', 'Id', 'Edit'];
  searchControl: UntypedFormControl = new UntypedFormControl(null);
  debounce: number = 1000;

  private _excelOutputFileName: string = 'SitesExcel.xlsx';
  private _csvOutputFileName: string = 'SitesCSV.csv';
  private _worksheetName: string = 'Sites';
  private _excelSiteColumns: string[] = ['Name', 'Code', 'Id', 'QR Code', 'QR Code Link'];
  private _csvSiteColumns: string[] = ['Name', 'Code', 'Id', 'QR Code Link'];
  private _qrDimension: number = 100;
  private _qrText = 'QR Code';

  constructor(
    private _siteService : SiteApiService,
    private _localizationService: LocalizationService,
    private _navigationService: NavigationService,
    public _router: Router,
    private _editDialog: MatDialog,
  ) {
    this._navigationService.onChange(true, false);
  }

  ngOnInit(): void {
    // Retrieve and sort Brands and Sites
    forkJoin({
      brands: this._siteService.getPartnersAdmin(true),
      sites: this._siteService.getSitesAdmin(undefined, true, true),
    }).subscribe(({ sites, brands }) => {
      if(brands != null && sites != null){
        this.brands = brands.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        this.brandsFilterDisplay = this.brands.filter(brand => brand.isActive == true);

        this.sites = sites.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        this.refreshSiteFilterList();
      }
    });
    
    this.searchControl.valueChanges
      .pipe(debounceTime(this.debounce), distinctUntilChanged())
      .subscribe(query => {
        if (query) {
          if (query.length > 2) {
            let queryLower = query.toLowerCase();
            this.filteredSites.data = this.searchedSites.filter(x =>
              x.name.toLowerCase().includes(queryLower))
              .map(s => { return { 
                site: s,
              } as ExtendedSite})
          }
        } else {
          this.filteredSites.data = this.searchedSites.map(s => { return { 
            site: s,
          } as ExtendedSite})
        }
      });
  }

  ngAfterViewInit(): void {
    this.filteredSites.paginator = this.paginator;
  }

  brandSelected(brand: Brand | undefined) {
    this.refreshSiteFilterList();

    this.selectedSite = null;
  }

  includeInactiveBrandsChecked(includeInactiveBrands: boolean) {
    if(this.brands != null){
      // Refresh the list of brands to display
      this.brandsFilterDisplay = this.brands.filter(brand => brand.isActive == true || brand.isActive == !this.includeInactiveBrands);

      // Clear selection if inactive brand selected when includeInactiveBrands changes to false
      if(!this.includeInactiveBrands && this.selectedBrand?.isActive == false){
        this.selectedBrand = null;
      }
      // Clear site selection if selected site's brand is inactive when includeInactiveBrands changes to false
      if(!this.includeInactiveBrands && this.selectedSite?.brand.isActive == false){
        this.selectedSite = null;
      }

      this.refreshSiteFilterList();
    }
  }

  includeInactiveSitesChecked(includeInactiveSites: boolean) {
    if(this.sites != null){
      // Clear selection if inactive site selected when includeInactiveSites is false
      if(!this.includeInactiveSites && this.selectedSite?.isActive == false){
        this.selectedSite = null;
      }

      this.refreshSiteFilterList();
    }
  }

  /**
   * Refreshes the Site filter list based on selected Brand and the includeInactiveSites flag.
   */
  refreshSiteFilterList(){
    this.sitesFilterDisplay = this.sites.filter(site => 
      (this.selectedBrand != null ? site.brand.id == this.selectedBrand.id : this.brandsFilterDisplay.some(brand => {return site.brand.id == brand.id}))
      && (site.isActive == true || site.isActive == !this.includeInactiveSites));
  }

  clearSelectedFilters(){
    this.selectedBrand = null;
    this.selectedSite = null;

    this.includeInactiveBrands = false;
    this.includeInactiveSites = false;
    this.includeInactiveBrandsChecked(false);
    this.includeInactiveSitesChecked(false);
  }
  
  search(){
    // Save the original list of searched sites to restore the table datasource when a table search filter is removed
    this.searchedSites = this.sites.filter(site => 
      (this.selectedBrand != null ? site.brand.id == this.selectedBrand.id : true) 
      && (this.selectedSite != null ? site.id == this.selectedSite.id : true)
      && (site.isActive == true || site.isActive == !this.includeInactiveSites)
    );

    this.filteredSites.data = this.searchedSites.map(s => { 
      return { 
        site: s,
      } as ExtendedSite});

    if(this.filteredSites.data.length == 0){
      alert(this._localizationService.translate('site_management_table_search_no_data'));
    }
  }

  editSite(site: Site){
    // TODO: Implement edit functionality
    let addUserDialog: MatDialogRef<SiteManagementEditComponent>;
    const dialogConfig = {
      data: { selectedSite: site },
      disableClose: false,
      autoFocus: false,
      maxWidth: '100vw !important',
      maxHeight: '100vw !important',
      panelClass: 'details-container'
    };
    addUserDialog = this._editDialog.open(SiteManagementEditComponent, dialogConfig);
    addUserDialog.addPanelClass('details-container');
  };

  openSearch() {
    this.searchControl.setValue('');
  };

  closeSearch() {
    this.searchControl.setValue(null);
  };

  showSearchButton() {
    return this.searchControl.value === null;
  };

  showSearchInput() {
    return this.searchControl.value !== null;
  };

  async exportToExcel(){
    if(this.searchedSites.length == 0){
      alert(this._localizationService.translate('site_management_table_export_no_data'));
      return;
    }

    const workbook = new ExcelJS.Workbook(); 
    const worksheet = workbook.addWorksheet(this._worksheetName);
    let worksheetColumns: { header: string, key: string }[] = [];

    // Add configured column headers to the worksheet
    this._excelSiteColumns.forEach(column => {
      worksheetColumns.push({ header: column, key: column.toLowerCase()})
    });
    worksheet.columns = worksheetColumns;
    
    // Insert Site data to the worksheet
    this.filteredSites.data.forEach(site => {
      worksheet.addRow({
        'name': site.site.name,
        'code': site.site.code,
        'id': site.site.id,
        'qr code': undefined, // Empty column to insert QR code image
        'qr code link': `${environment.qrCodeLoginUrl}/${site.site.id}` ,
      });
    });

    let rowCount = 1;
    let qrColumnNo = this._excelSiteColumns.indexOf(this._qrText);
    // Generate QR code images for each site and insert them into the worksheet
    this.filteredSites.data.forEach(site => {
      QRCode.toDataURL(`${environment.qrCodeLoginUrl}/${site.site.id}`, (err, url) => {
        let imageID = workbook.addImage({
          base64: url,
          extension: 'jpeg',
        });
        worksheet.addImage(imageID, {
          tl: { col: qrColumnNo, row: rowCount },
          ext: { width: this._qrDimension, height: this._qrDimension },
          editAs: 'oneCell'
        });
      })

      rowCount++;
    });

    this.resizeExcelRowsAndColumns(worksheet);

    const buffer = await workbook.xlsx.writeBuffer();
    var FileSaver = require('file-saver');
    FileSaver.saveAs(new Blob([buffer]), this._excelOutputFileName);
  }

  private resizeExcelRowsAndColumns(worksheet: Worksheet){
    let qrColumnNo = this._excelSiteColumns.indexOf(this._qrText);

    // Resize rows
    worksheet.eachRow(row => {
      if(row.number == 1) return;
      
      row.height = this._qrDimension;
    });

    // Resize columns
    worksheet.eachColumnKey(column => {
      let maxLength = 0;

      if(column.number == qrColumnNo + 1){
        column.width = (8.43 / 64) * this._qrDimension + 1; // +1 for buffer
        return;
      }
      else{
        column.eachCell(cell => {
          var columnLength = cell.value ? cell.value.toString().length : 10;
          if (columnLength > maxLength ) {
              maxLength = columnLength;
          }
        });

        column.width = maxLength < 10 ? 10 : maxLength;
      }
    });
  }

  // CSV export doesn't support images
  async exportToCSV(){
    if(this.searchedSites.length == 0){
      alert(this._localizationService.translate('site_management_table_export_no_data'));
      return;
    }

    const workbook = new ExcelJS.Workbook(); 
    const worksheet = workbook.addWorksheet(this._worksheetName);
    let worksheetColumns: { header: string, key: string }[] = [];

    // Add configured column headers to the worksheet
    this._csvSiteColumns.forEach(column => {
      worksheetColumns.push({ header: column, key: column.toLowerCase() })
    });
    worksheet.columns = worksheetColumns;

    // Insert Site data to the worksheet
    this.filteredSites.data.forEach(site => {
      worksheet.addRow({
        'name': site.site.name,
        'code': site.site.code,
        'id': site.site.id,
        'qr code link': `${environment.qrCodeLoginUrl}/${site.site.id}` ,
      });
    });

    const buffer = await workbook.csv.writeBuffer();
    var FileSaver = require('file-saver');
    FileSaver.saveAs(new Blob([buffer]), this._csvOutputFileName);
  }

  closeSiteManagement(){
    this._router.navigate(['signin']);
  }
}
