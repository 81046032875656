import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { UserType } from '@app/shared/enums';

@Injectable({
  providedIn: 'root'
})
export class UserAdminGuard implements CanActivate {
  constructor(private _authService : AuthService, 
    private _router: Router){};

    public async canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Promise<boolean> {
     
        const roles = await this._authService.getUserRoles();
        if (roles && roles.length > 0 && roles.includes(UserType.CITY_ADMIN)) {
          return true;
        }else{
          await this._authService.logout();
          this._router.navigate(['login']);
        }

        return false;
    }
  
}
