import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserService } from './user.service';
import { AuthService } from '../auth/auth.service';
import { LocalizationService } from '@app/components/internationalization/localization.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserGuard implements CanActivate {
  constructor(private _userService: UserService, 
    private _authService : AuthService, 
    private _localizationService : LocalizationService,
    private _router: Router){};

  public async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
      const signIn = await this._userService.getSignIn();
      if (signIn != undefined &&  signIn.signInSignOutId != ""){
        const currentUser = await this._authService.getUser();
        if (currentUser) {
  
          const result = await firstValueFrom(this._userService.checkSignOut());
          if (result){
            alert(this._localizationService.translate('userguard_user_signedout'));
            await this._userService.removeSignIn();
            this._router.navigate(['signin']);
            return false;
          }
          else{
            return true;
          }
        }
        this._router.navigate(['signin']);
        return false;
      }

      return true;
      
      
  }
  
}