<mat-toolbar class="bg-white mat-toolbar-override">
    <button mat-icon-button (click)="back()" id="btn_qrsigninback">
        <mat-icon color="primary">
            keyboard_backspace
        </mat-icon>
    </button>
</mat-toolbar> 

<div *ngIf="locationMessage != ''">
    <div fxLayout="row">
        <div fxLayout="column" fxFlex="100%">
            <div class="alert alert-danger">
                <p>
                    {{locationMessage}}
                </p>
            </div>
        </div>
    </div>
</div>

<div *ngIf="showSpinner" class="overlay">
    <mat-progress-spinner class="spinner" mode="indeterminate">
    </mat-progress-spinner>
</div>

<div *ngIf="locationMessage == ''">

    <div *ngIf="!isMobileNative" >

        <ngx-scanner-qrcode [hidden]="!scanActive" #action="scanner" 
        (event)="qrScanned($event)" (error)="onError($event)"></ngx-scanner-qrcode>

        <button name= "btn_camera_toggle" id ="btn_camera_toggle" mat-raised-button (click)="handle(action, 'toggleCamera')" >
            {{ action.isStart ? ('qrsignin_lbl_stop_scan' | translate) : ('qrsignin_lbl_scan_qr_code' | translate) }}
        </button>

    </div>


    <table *ngIf="isMobileNative"  class="qr-div">
        <tr>
            <td colspan="3" class="qr-box-top">  </td>
        </tr>
        <tr>
            <td class="qr-box"> </td>
            <td class="qr-scan-box"> </td>
            <td class="qr-box"> </td>
        </tr>
        <tr>
            <td colspan="3" class="qr-box">  </td>
        </tr>
    </table>
</div>


