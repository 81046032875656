import { Component, Input, OnInit } from '@angular/core';
import { PermitService } from '@app/services/permit/permit-service';

@Component({
  selector: 'app-permit-radiobutton',
  templateUrl: './permit-radiobutton.component.html',
  styleUrls: ['./permit-radiobutton.component.scss']
})
export class PermitRadioButtonComponent implements OnInit {

  @Input('question') question: any;
  @Input() hasContentError: boolean = false;
  options: string[] = [];
  selectedOption: string;
  nextQuestions: any;
  isRequiredMessageDisplay: string = '';

  constructor(private _permitService: PermitService) { }
  ngOnInit(): void {
    if (this.question && this.question.QuestionDetails) {
      this.options = this.question.QuestionDetails.split('|');

      if(this.question.Answer !== null && this.question.Answer !== undefined)
      {
        this.onOptionSelected(this.question.Answer);
      }
    }

    if(this.question.IsRequired)
    {
      this.isRequiredMessageDisplay = this._permitService
        .determineIsRequiredMessage(this.question.IsRequiredMessage);
    }
  }

  onOptionSelected(option: string): void {
    const originalAnswer = this.question.Answer;

    this.selectedOption = option;
    this.question.Answer = option;

    if (this.question.Rules !== null && this.question.Rules.length > 0) {
      const rule = this.question.Rules.find((rule: any) => rule.Value.trim().toLowerCase() === this.selectedOption.trim().toLowerCase());
      if (rule.RuleDetails !== null && rule.RuleDetails.length > 0) {
        this.nextQuestions = rule.RuleDetails;
      } else {
        this.nextQuestions = null;
      }
    }

    if(originalAnswer !== null){
      // Clear subquestion answers of the previously selected answer
      this._permitService.clearSubQuestionAnswers(this.question, true, originalAnswer);
    }
  }

  captionStyles(): object {
    return JSON.parse(this.question.CaptionStyles);
  }

  questionDetailsStyles() : object {
    return JSON.parse(this.question.QuestionDetailsStyles);
  }
}
