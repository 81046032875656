import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { SiteService } from './site.service';

@Injectable({
  providedIn: 'root'
})
export class SiteGuard implements CanActivate {
  constructor(private _siteService: SiteService, private _router: Router){};

  public async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
      const isAuthenticated = await this._siteService.isSiteAuthenticated();
      if(isAuthenticated){
        return true;
      }

      this._router.navigate(['signin']);
      return false;
  }
  
}
